import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import { useStaticQuery, graphql } from "gatsby" 
import CategoryFilter from "./Sections/CategoryFilter"  
import {Landlords} from "../../../queries/common_use_query" 
import { useContainerContext } from "../../../store/ContainerContext";
import Newsletter from "../../forms/newsletter-form-popup"

const LandlordsFilter = (props) => {

    const context = useContainerContext();
    const {filter_by_cat} = context.state;
    const {FilterByCatDispatch, FilterByServiceDispatch, AllInsightsDispatch} = context.actions;
    
    const { loading, error, data } = Landlords();

    const [isOpen,setIsOpen] = useState(false);
    const [insight,setInsight] = useState(false);

    const [categoryName,setCategoryName] = useState("");
    const global_data = useStaticQuery(graphql`
    query LandlordSubscribeQuery {  
      glstrapi{

        globalModule {
            Subscribe_Updates {
              Title
              Content
            }
        }

      }
    }`)
    var Subscribe_Updates = global_data.glstrapi?.globalModule?.Subscribe_Updates;
    const handlerSearchClick = (cname) => {
        setCategoryName(cname);
    }

    let newsAndInsights = data?.landlords || []; 
    if (props.alias && newsAndInsights.length > 0) {
        if(!insight){
            setInsight(true)
            AllInsightsDispatch(newsAndInsights);
        } 
    }
    return (
        <React.Fragment>
            <div className="banner-bottom banner_filter">
                <Container>
                    <Row>
                        <Col>
                            <div className="bottom-wrapper"> 
                                <div className="select-box-block"> 
                                    <CategoryFilter handleClikCat={handlerSearchClick} data={newsAndInsights} filter_by_val={filter_by_cat} FilterByDispatchSer={FilterByServiceDispatch} FilterByDispatch={FilterByCatDispatch}/>  
                                </div>
                                <div className="subscribe">
                                    <a href="javascript:;"  onClick={()=>setIsOpen(true)}><i className="icon-msg"></i> Subscribe to email updates</a>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                id="popup_modal_form"
                show={isOpen}
                onHide={() => setIsOpen(false)}
                backdrop="static"
                keyboard={false}
                className="member-contact-modal"
                dialogClassName="modal-dialog-centered"
            >
                {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
                <Modal.Header closeButton className="contact-close-btn memberPopup">
                    {/* {
                        Subscribe_Updates && 
                        <Modal.Title className="w-100">
                            <h3 className="text-center content-primary-color mb-0">{Subscribe_Updates.Title}</h3>
                            <p className="mb-0">{Subscribe_Updates.Content}</p>
                        </Modal.Title>
                    } */}
                    
                </Modal.Header>
                <Modal.Body>
                    <Newsletter />
                </Modal.Body>
            </Modal>
        </React.Fragment >
    );
};
export default LandlordsFilter;
